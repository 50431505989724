import axios from 'axios';
import http from './http';
import docCookies from '@/utils/cookie';

const cookieKey = '.auth.token';
export function removeRefreshToken() {
  return docCookies.removeItem(cookieKey);
}

export function getRefreshToken() {
  return docCookies.getItem(cookieKey);
}
// Dev Cookie Setting
export function setDevTooken(token) {
  docCookies.setItem(cookieKey, token);
}

function setRefreshToken(token) {
  docCookies.setItem(cookieKey, token);
}

export function authorizeCode(code, redirect_uri) {
  const data = {
    grant_type: 'authorization_code',
    code,
    redirect_uri,
  };
  return getToken(data);
}
export function refreshToken(refresh_token) {
  const data = {
    grant_type: 'refresh_token',
    refresh_token,
  };
  return getToken(data);
}

function getToken(data) {
  data.client_id = 'web';
  data.client_secret = 'test';
  return new Promise((resolve, reject) => {
    axios
      .post(window.ENV_CFG.token, data)
      .then(res => {
        if (res.data?.access_token) {
          http.setAuthorization(res.data);
          setRefreshToken(res.data.refresh_token);
          resolve(res.data);
        } else {
          reject(res);
        }
      })
      .catch(e => {
        reject(e);
      });
  });
}

 export function logout() {
  return http.get('/Account/logout');
 }
